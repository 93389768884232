import React from 'react';
import styled from 'styled-components';
import {Tag, Alert, Button, message} from 'antd';
import {Helmet} from 'react-helmet';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../../data.json';
import WsProxy from '../../TbaWsProxy';
import LoginRequired from '../../Components/LoginRequired';
import PermissionRequired from '../../Components/PermissionRequired';
import LiveStreamSignModal from '../../Components/LiveStreamSignModal';

const WS_STATE_DISPLAY = {
  0: '尚未連線',
  1: '正在連線',
  2: '已連線',
  3: '結束連線',
};

const WS_STATE_COLOR = {
  0: 'default',
  1: 'cyan',
  2: 'green',
  3: 'magenta',
};

const LS_STATE_DISPLAY = {
  '': '直播未開啟',
  on: '直播中',
  off: '直播結束',
};

const LS_STATE_COLOR = {
  '': '#2db7f5',
  on: '#f50',
  off: '#108ee9',
};

function accumulateViemtime({user_id, live_stream_id, diff}) {
  const itemStr = window.localStorage.getItem(
    `live_stream_${user_id}_${live_stream_id}`,
  );

  if (!itemStr) {
    const nextItem = JSON.stringify({
      played: diff,
      start: new Date().getTime(),
      end: new Date().getTime(),
    });
    window.localStorage.setItem(
      `live_stream_${user_id}_${live_stream_id}`,
      nextItem,
    );
  } else {
    const item = JSON.parse(itemStr);
    const nextItem = JSON.stringify({
      played: item.played + diff,
      start: item.start,
      end: new Date().getTime(),
    });
    window.localStorage.setItem(
      `live_stream_${user_id}_${live_stream_id}`,
      nextItem,
    );
  }
}

async function updateViewtime({user_id, live_stream_id, product_id, onFinish}) {
  try {
    const itemStr = window.localStorage.getItem(
      `live_stream_${user_id}_${live_stream_id}`,
    );

    if (itemStr) {
      const item = JSON.parse(itemStr);

      if (item.played > 0) {
        await AppActionsEx.updateLiveStreamViewtime({
          product_id,
          data: {
            live_stream_id,
            product_id,
            played: item.played,
            start: item.start,
            end: item.end,
            user_agent: window.navigator.userAgent,
          },
        });
      }
    }

    onFinish();
  } catch (err) {
    console.warn(err);
  }
}

function trackVideoPlaybackStats({live_stream_id, user_id, product_id, stats}) {
  const el = document.querySelector('mux-player');
  stats.onplay = false;
  stats.updating = false;

  el.addEventListener('play', () => {
    console.log('---------------play');
    stats.onplay = true;
  });

  el.addEventListener('timeupdate', (e) => {
    console.log('---------------timeupdate');

    if (!stats.start_time) {
      stats.start_time = new Date().getTime();
    } else if (el.currentTime > 0) {
      const current_time = new Date().getTime();
      const diff = Math.abs(current_time - stats.start_time);

      accumulateViemtime({user_id, live_stream_id, diff});
      stats.start_time = current_time;
    }
  });

  el.addEventListener('pause', () => {
    console.log('---------------pause');
    stats.onplay = false;

    if (!stats.onplay && !stats.updating) {
      stats.updating = true;

      updateViewtime({
        user_id,
        live_stream_id,
        product_id,
        onFinish: () => {
          stats.updating = false;
        },
      });
    }
  });
}

function LiveStreamDetail(props) {
  const params = qs.parse(props.location.search);
  const [updateCnt, setUpdateCnt] = React.useState(0);
  const [liveStream, setLiveStream] = React.useState(null);
  const [playbackId, setPlaybackId] = React.useState(null);
  const [playbackToken, setPlaybackToken] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  const [user] = useOutlet('user');
  const [viewCount, setViewCount] = React.useState(0);
  const [liveStreamStatus, setLiveStreamStatus] = React.useState('');
  const wsProxy = React.useRef(new WsProxy()).current;
  const [permission, setPermission] = React.useState(true);

  const [showSignModal, setShowSignModal] = React.useState(false);
  const [signId, setSignId] = React.useState(null);
  const videoPlaybackStatsRef = React.useRef({});

  React.useEffect(() => {
    let timer;

    if (user && product && params.id) {
      wsProxy.init({
        url: 'wss://xsds0n2oal.execute-api.ap-northeast-1.amazonaws.com/prod',
        product_id: product.id,
        live_stream_id: params.id,
        token: user.token,
        is_admin: false,
        render: (data) => {
          if (data === undefined) {
            setUpdateCnt((cnt) => cnt + 1);
            return;
          }

          if (data.resp?.view_count) {
            setViewCount(parseInt(data.resp?.view_count));
          }

          if (data.resp?.live_stream_status) {
            setLiveStreamStatus(data.resp?.live_stream_status);
          }

          if (data.resp?.action === 'sign_on') {
            setShowSignModal(true);
            setSignId(data.resp?.sign_oid);
          }

          if (data.resp?.action === 'sign_close') {
            setShowSignModal(false);
            setSignId(null);
          }
        },
      });
      wsProxy.connect();

      timer = setInterval(() => {
        wsProxy.sendGetStatus();
      }, 30 * 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [wsProxy, user, product, params.id]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const v = await JStorage.fetchOneDocument('live_stream', {
          id: params.id,
        });
        setLiveStream(v);

        if (v.permission === 'public' || !v.permission) {
          setPlaybackId(v?.playback_ids[0]?.id);
        } else if (v.permission === 'signed') {
          const resp = await ApiUtil.req(
            `${Config.apiHost}/mux/jwt?token=${user.token}`,
            {
              method: 'POST',
              data: {live_stream_id: v.id},
            },
          );

          setPlaybackId(resp.playback_id);
          setPlaybackToken(resp.mux_token);
        }

        const p = await JStorage.fetchOneDocument('product', {id: v.product});
        setProduct(p);

        // wait a little longer for mux library loading
        await AppActions.delay(600);
      } catch (ex) {
        console.warn('LiveStreamPage ex', ex);

        if (ex.response?.error === 'video_permission_denied') {
          setPermission(false);
        }
      }
      AppActions.setLoading(false);
    }

    if (user && params.id) {
      fetchData();
    }
  }, [params.id, user]);

  React.useEffect(() => {
    if (user && user.data && user.data.owner && product && liveStream) {
      trackVideoPlaybackStats({
        user_id: user.data.owner,
        live_stream_id: liveStream.id,
        product_id: product.id,
        stats: videoPlaybackStatsRef.current,
      });
    }
  }, [user, product, liveStream]);

  React.useEffect(() => {
    let timer;

    if (user && user.data && user.data.owner && product && liveStream) {
      timer = setInterval(() => {
        updateViewtime({
          user_id: user.data.owner,
          live_stream_id: liveStream.id,
          product_id: product.id,
          onFinish: () => 0,
        });
      }, 10 * 1000 * 60);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [user, product, liveStream]);

  if (!user) {
    return <LoginRequired />;
  }

  if (!permission) {
    return <PermissionRequired />;
  }

  return (
    <Wrapper>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/@mux/mux-player"></script>
      </Helmet>

      {product && (
        <>
          {showSignModal && (
            <LiveStreamSignModal
              user={user}
              signId={signId}
              visible={showSignModal}
              setVisible={() => setShowSignModal(false)}
            />
          )}
          <div className="container">
            <h2 className="title">{product.name}</h2>

            <div className="control">
              <div className="time">
                直播時間：
                {product.session.date} {product.session.start_time}-
                {product.session.end_time}
              </div>

              <div className="status">
                {/* <Tag size="large" color={LS_STATE_COLOR[liveStreamStatus]}>
                  {LS_STATE_DISPLAY[liveStreamStatus]}
                </Tag> */}
                {/* <Tag size="large" color={WS_STATE_COLOR[wsProxy.state]}>
                  {WS_STATE_DISPLAY[wsProxy.state]}
                </Tag> */}
                {/* 觀看人數： {viewCount} 人 */}
              </div>
            </div>

            {/* {user && user.data && user.data.owner && product && liveStream && (
              <div className="alert">
                <Alert
                  showIcon
                  type="success"
                  size="small"
                  message={
                    <div>
                      提醒您：直播結束或中途離開，敬請點擊「上傳觀看時長」按鈕，再關閉本視窗。
                    </div>
                  }
                  style={{marginBottom: 3}}
                />
                <Button
                  type="primary"
                  style={{margin: 5}}
                  onClick={async () => {
                    try {
                      await updateViewtime({
                        user_id: user.data.owner,
                        live_stream_id: liveStream.id,
                        product_id: product.id,
                        onFinish: () => 0,
                      });

                      message.success('上傳成功');
                    } catch (err) {
                      message.error('上傳失敗');
                    }
                  }}>
                  上傳觀看時長
                </Button>
              </div>
            )} */}

            {liveStream && playbackId && (
              <div className="content">
                {playbackToken ? (
                  <mux-player
                    stream-type="on-demand"
                    title={liveStream.name}
                    playback-id={playbackId}
                    playback-token={playbackToken}></mux-player>
                ) : (
                  <mux-player
                    stream-type="on-demand"
                    title={liveStream.name}
                    playback-id={playbackId}></mux-player>
                )}
              </div>
            )}
          </div>
          <div className="container">
            <div className="content">{liveStream.description}</div>
            <div className="content">{product.spec}</div>
            <div className="content">{product.intro}</div>
            <div className="content">{product.remark}</div>

            {liveStream &&
              liveStream.materials &&
              liveStream.materials.length > 0 && (
                <div className="content">
                  <h3 className="title">講義下載</h3>
                  {liveStream.materials.map((material, index) => (
                    <div key={`material-${index}`} className="item">
                      <a target="_blank" href={material.src} rel="noreferrer">
                        {material.name}
                      </a>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  padding-top: var(--topNavBarHeight);
  background-color: var(--pageBackgroundColor);
  min-height: 100vh;

  & > .container {
    margin: 30px auto;
    max-width: 1024px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;

    & > .title {
      margin-bottom: 5px;
      font-weight: 600;
    }

    & > .control {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > .time {
        margin-right: 10px;
        font-weight: 500;
        font-size: 1rem;
        color: var(--secondColor);
      }

      & > .status {
        margin-top: 5px;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 1rem;
        color: var(--primaryColor);
      }
    }

    & > .alert {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    & > .content {
      margin: 10px auto;
      font-size: 1rem;
      line-height: 2rem;

      & > .title {
        margin: 10px 0px;
        color: var(--secondColor);
      }
    }
  }
`;

export default LiveStreamDetail;
