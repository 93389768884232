import React from 'react';
import styled from 'styled-components';
import {Modal, Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

export default function LiveSteamSignModal(props) {
  const {user, signId, visible, setVisible} = props;

  return (
    <Modal
      title={
        <div>
          <h3>您收到一則點名通知</h3>
        </div>
      }
      footer={
        <div style={{padding: 5}}>
          <Button
            type="primary"
            onClick={async () => {
              try {
                await JStorage.createDocument('sign_history', {
                  sign_id: signId,
                  owner: user.data.owner,
                });

                setVisible(false);
                message.success('完成點名');
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              }
            }}>
            是的，我在線觀看
          </Button>
        </div>
      }
      visible={visible}
      style={{padding: 10}}
      onOk={async () => {}}
      onCancel={() => setVisible(false)}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  return (
    <ModalContentWrapper>
      <div>收到這則訊息，代表我們想瞭解您是否在線觀看。</div>
      <div>請按下「是的，我在線觀看」按鈕，完成點名。</div>
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div`
  font-size: 16px;。
`;
